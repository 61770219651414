<template>

    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                    
                        <!-- <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sites"
                            label="site_name"
                            class="w-100"
                            placeholder="Project Site"
                            v-model="siteData"
                            @input="filterTable"
                        /> -->

                        <b-form-select v-model="siteData" @change="filterTable(); updateDateFormat();">

                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                          
                          <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                          
                          
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                        <b-form-datepicker v-model="start" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="updateStart()"/>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mobile_tab_max_width_flex">
                        <b-form-datepicker @change="filterTable" v-model="end" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="filterTable" :min="disabledDates()"/>
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6" class="mobile_tab_max_width_flex all_btn_tab">
                        <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1 mobile_margin_bottom_1rem"
                                placeholder="Search..."
                                @input="filterTable"
                            />

                            <b-button
                              v-if="checkPermission('custom-forms-dashboard', 'Zip Download')"
                              :disabled="selected && selected.length > 0 ? false:true"
                              variant="primary"
                              class=" "
                              @click="zipDownload()"
                            >
                              <span class="text-nowrap">Zip Download</span>
                            </b-button>

                            <!-- <b-button
                              v-if="checkPermission('custom-forms-dashboard', 'Delete')"
                              :disabled="selected && selected.length > 0 ? false:true"
                              variant="danger"
                              class="mr-1 mobile-margin-bottom"
                              @click="multipleDelete()"
                            >
                              <span class="text-nowrap">Delete</span>
                            </b-button> -->
                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "checkPermission('custom-forms-dashboard', 'Zip Download') ? tableColumns2 : tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
                :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
            >
                <template #head(checkbox)="items">
                  <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="getAll"/>
                </template>

                <template #cell(checkbox)="items">
                      <!-- v-if="checkPermission('custom-forms-dashboard', 'Delete')" -->
                    <b-form-checkbox 
                      v-model="selected" 
                      :value="items.item._id" 
                      class="custom-control-warning my-1"
                    />
                </template>

                <template #cell(unique_id)="items">
                    <span class="wordBreak">{{ items.item.unique_id | capitalize}}</span>
                </template>

                <template #cell(site_name)="items">
                    <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>
                </template>

                <template #cell(conducted_by)="items">
                    <span class="wordBreak">{{ items.item.conducted_by | capitalize}}</span>
                </template>

                <template #cell(conducted_date)="items">
                    <!-- <span>{{items.item.conducted_date | dateTime2}}</span> -->
                    <span>{{sitedateTime2(items.item.conducted_date,date_format,'' )}}</span>
                </template>

                <template #cell(actions)="items">

                    <b-link
                          v-if="checkPermission('custom-forms-dashboard', 'Delete')"
                          v-b-tooltip.hover.v-warning
                          title="Delete"
                          variant="outline-warning"
                          @click="deleteItem(items.item._id)"
                        >
                          <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
                            
                      </b-link>

                    <b-link
                        v-if="checkPermission('custom-forms-dashboard', 'Download')"
                        v-b-tooltip.hover.v-warning
                        title="Download"
                        variant="outline-warning"
                        @click="download(items.item.pdf)"
                    >
                        <feather-icon icon="DownloadIcon" class="mr-1 mediumSize"/>
                    </b-link>
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>
    </div>
</template>

<script>
    import {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption,BBreadcrumb,BFormCheckbox
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { POST_API } from "../../../store/actions.type"
    import moment from "moment";

    export default {
        components: {
            BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption,BBreadcrumb,BFormCheckbox
        },
        directives: {
            'b-tooltip': VBTooltip
        },
        data() {
            return {
                date_format:'DD MMM YYYY',
                time_format:'HH:mm',
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 

                tableColumns: [
                    { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '25%'}},
                    { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '20%'}},
                    { key: 'conducted_by', label: 'Conducted By', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'conducted_date', label: 'Conducted Date', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
                ],
                tableColumns2: [
                    { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '5%'}},
                    { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '20%'}},
                    { key: 'conducted_by', label: 'Conducted By', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'conducted_date', label: 'Conducted Date', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
                ],

                sites:[],
                siteData:''/*this.$route.params.site_id*/,   
                
                // b-table and pagination options
                items           : [],
                isSortDirDesc   : true,
                perPageOptions  : [10, 20, 50, 100],
                perPage         : 10,
                sortBy          : 'id',
                totalRecords    : 0,
                currentPage     : 1,
                searchQuery     : '',
                from            : null,
                to              : null, 
                // calendar vars 
                start:moment().tz('Asia/Singapore').toDate(),
                end:moment().tz('Asia/Singapore').toDate(),

                // select all vars
                selectAll:false,
                selected:[],
            }
        },

        methods : {
            getAll(e){
                this.selected = [];        
                if (e) {
                var role = this.$store.getters.currentUser.role;
                var selected = [];
                this.items.forEach(function(item){
                    // if((item.level_user_status != null) && (item.level_user_status.role == role)){
                    selected.push(item._id);
                    // }
                })
                this.selected = selected;
                }
            },

            multipleDelete(){
                if (this.selected.length > 0) {
                this.decisionAlert('Are you sure you want to delete selected reports?')
                .then(result => {
                    if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                            id: this.selected,
                            },
                        api:"/api/delete-multiple-tm-reports",
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                        } else {
                            this.successAlert();
                            
                            this.selected = [];
                            this.selectAll = false;
                
                            this.filterTable();
                        }
                    });
                    }
                })
                .catch(err => {})
                }else{
                this.customAlert('Please select reports.', '', 'warning')
                }
            },

            dataList(){
                this.selectAll = false;
                this.selected  = [];

                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                        role          : this.$store.getters.currentUser.role,
                        om_sites      : this.$store.getters.currentUser.om_sites,
                        site          : this.siteData != ''? this.siteData :null,
                        start:moment(this.start).format('YYYY-MM-DD'),
                        end:moment(this.end).format('YYYY-MM-DD'),
                    },
                    api: '/api/toolbox-meeting-reports'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },

            filterTable(){
                this.$refs.refUserListTable.refresh();
            },

            variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },
            
            download(pdf){
                window.open(pdf, '_blank');
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.sites = this.$store.getters.getResults.data;
                        // if (this.sites.length == 1 ) {
                        //     this.siteData = this.sites[0]._id;
                        //     this.filterTable();
                        //     this.updateDateFormat();
                        // }
                        var obj = {
                            _id:'all-site',
                            site_name:'All Project Sites',
                            pdf_date_format: 'DD MMM YYYY',
                            pdf_time_format: 'HH:mm'
                        }
                        this.sites.unshift(obj);
                        
                        this.siteData = this.defaultSite(this.sites)._id;
                        this.filterTable();
                        this.updateDateFormat();
                        
                        return this.sites;
                    }
                });
            },

            // calendar functions
            updateStart(){
                this.end = moment(this.start).tz('Asia/Singapore').toDate();
                this.filterTable();
            },
            disabledDates(){
                if (this.start) {
                    return moment(this.start).format('YYYY-MM-DD');
                }else{
                    return moment().format('YYYY-MM-DD');
                }
            },
            deleteItem(id){
              var msg = 'Are you sure want to delete this record?';
              var message='Toolbox Meeting Report Deleted Successfully.';

              Swal.fire({
                        title: 'Please Confirm',
                        text: msg,
                        icon: 'warning',
                        position: 'top-center',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                      })
                    .then(result => {
                      if (result.value) {
                          return this.$store.dispatch(POST_API, {
                            data:{
                                  id: id,
                                },
                            api:"/api/delete-toolbox-meeting-report",
                              })
                              .then(() => {
                                  if (this.$store.getters.containsErrors) {
                                      this.error_message = this.$store.getters.getErrors;
                                      this.showAlert();
                                  } else {
                                    
                                    Swal.fire({
                                      position: 'center',
                                      icon: 'success',
                                      title: message,
                                      showConfirmButton: false,
                                      timer: 1500
                                    })
                                    
                                    this.$refs.refUserListTable.refresh();
                                  }
                              });
                      }
                  })
                  .catch(err => {
                      
                  })
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:null,
                text: 'Reports',
              },{
                to:{name:'custom-forms-dashboard'},
                text: 'Form Dashboard',
              },{
                to:{name:'custom-forms-reports'},
                text:'Custom Forms'
              },{
                to:null,
                text:'Toolbox Meeting Report',
                active:true
              }];
              return this.filterReportBreadCrum(item);
            },
            updateDateFormat(){
              if (this.siteData != '') {
                  this.sites.forEach(item => {
                    if (item._id == this.siteData) {
                        this.date_format = item.pdf_date_format;
                        this.time_format = item.pdf_time_format; 
                    }
                  })

              }else{
                  this.date_format = 'DD MMM YYYY';
                  this.time_format = 'HH:mm';
              }
            },

            zipDownload(){
                var organization  = this.$store.getters.currentUser.organization;
                var id            = this.selected && this.selected.length > 0 ? this.selected.toString() : [];
                var start         = moment(this.start).format('YYYY-MM-DD');
                var end           = moment(this.end).format('YYYY-MM-DD');
                
                var params  = "organization="+organization+"&id="+id+"&start="+start+"&end="+end;
                var baseurl = "";

                baseurl        = process.env.VUE_APP_SERVER_URL + '/api/tm-zip-report?' + params;
                this.selectAll = false,
                this.selected  = []
                
                window.open(baseurl,'_blank');
            },

            // zipDownload(){
            //     return this.$store.dispatch(POST_API, {
            //         data:{
            //                 keyword       : this.searchQuery,
            //                 sortBy        : this.sortBy,
            //                 sortDirection : this.isSortDirDesc,
            //                 role          : this.$store.getters.currentUser.role,
            //                 om_sites      : this.$store.getters.currentUser.om_sites,
            //                 site          : this.siteData != '' ? this.siteData :null,
            //                 start         : moment(this.start).format('YYYY-MM-DD'),
            //                 end           : moment(this.end).format('YYYY-MM-DD'),
            //                 id            : this.selected,
            //             },
            //         api:"/api/tm-zip-report",
            //     })
            //     .then(() => {
            //         if (this.$store.getters.containsErrors) {
            //             this.error_message = this.$store.getters.getErrors;
            //             this.errorAlert();
            //         } else {
            //             this.selectAll = false,
            //             this.selected  = []

            //             var data = this.$store.getters.getResults.data;

            //             if (data) {
                        
            //             var link = document.createElement("a");
            //             link.download = data.name;
            //             link.href = data.file;
            //             document.body.appendChild(link);
            //             link.click();
            //             document.body.removeChild(link);
            //             //delete link;
            //             }                   
                    
            //         }
            //     });
            // }
        },
        mounted(){
            this.allSites();
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
